<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('common.resource.payment_option.plural')
    }
  }
}
</script>
